<i18n>
ru:
  button: Отменить заказ
  createdAt: Заказ от {time}
  details: Подробнее
  detailsHide: Свернуть
  itemInOrder: 1 позиция в заказе
  itemsInOrder: '{count} позиций в заказе'
  title: У вас есть незавершённый заказ
  warning: При оформлении заказа возникла ошибка!
  warningSubtext: Если в этом заказе вы применили промокод или получили подарок, то
    отмените заказ, чтобы ваш промокод (подарок) стал снова доступен.
  warningTimer: 'Заказ отменится автоматически через:'
ua:
  button: Скасувати замовлення
  createdAt: Замовлення створено в {time}
  details: Детальніше
  detailsHide: Згорнути
  itemInOrder: 1 позиція в замовленні
  itemsInOrder: '{count} позицій в замовленні'
  title: У вас є незавершене замовлення
  warning: При оформленні замовлення виникла помилка!
  warningSubtext: Якщо в цьому замовленні ви застосували промокод або отримали подарунок,
    то скасуйте замовлення, щоб ваш промокод (подарунок) став знову доступний.
  warningTimer: 'Замовлення скасується автоматично через:'
us:
  button: Cancel order
  createdAt: Order was created at {time}
  details: Details
  detailsHide: Minimize
  itemInOrder: 1 item in the order
  itemsInOrder: '{count} items in order'
  title: You have an uncompleted order
  warning: An error occurred while placing an order!
  warningSubtext: If you used a promo code or gift on this order, please cancel it
    to use the promotion again.
  warningTimer: 'The order will be automatically canceled after:'
</i18n>

<template>
  <div
    v-if="orders.length > 0"
    class="v-order-cancel-wrapper"
  >
    <template
      v-for="(order, index) in orders"
      :key="order"
    >
      <div class="v-order-cancel">
        <div
          class="v-order-cancel-title"
          v-html="translate('orderCancel.title')"
        />
        <div
          class="v-order-cancel-open-details"
          @click="() => expandDetails(index)"
        >
          <span
            class="v-order-cancel-open-details-text"
            v-html="translate(expanded[index] ? 'orderCancel.detailsHide' : 'orderCancel.details')"
          />
          <icon-general-chevron-right
            class="v-order-cancel-open-details-sign"
            :class="{ 'v-order-cancel-open-details-sign-rotated': expanded[index] }"
          />
        </div>

        <div class="v-order-cancel-countdown">
          <span
            class="v-order-cancel-countdown-warning"
            v-html="translate('orderCancel.warningTimer')"
          />
          <common-countdown
            use-primary-color
            :expires="order.OrderLifeTime"
            :on-timer-end-event="orderCancelEvent"
          />
        </div>
        <div
          v-if="alwaysShowDate || orders.length > 1"
          class="v-order-cancel-date"
        >
          <span v-html="translate('orderCancel.createdAt', { time: makeCreatedAt(order.Created) })" />

          <arora-nuxt-link
            v-if="isExtraSmall"
            class-name="v-order-cancel-details-link"
            :label="
              order.Items.length === 1
                ? translate('orderCancel.itemInOrder')
                : translate('orderCancel.itemsInOrder', { count: order.Items.length })
            "
            :href="`${appConfig.VueSettingsPreRun.Links.CartFinalStep}/${order.ID}`"
          />
        </div>
        <transition
          appear
          mode="out-in"
          name="slidedown"
        >
          <div
            v-if="expanded[index]"
            class="v-order-cancel-details"
          >
            <div class="v-order-cancel-details-text-wrapper">
              <div
                class="v-order-cancel-details-text"
                v-html="translate('orderCancel.warning')"
              />
              <div
                class="v-order-cancel-details-subtext"
                v-html="translate('orderCancel.warningSubtext')"
              />
            </div>
            <div
              v-if="order.Items.length > 0 && !isExtraSmall"
              class="v-order-cancel-details-pic"
            >
              <arora-nuxt-image
                :alt="order.Items[0].Name"
                :image="order.Items[0].SmallImage"
                image-type="ProductSmall"
              />
              <arora-nuxt-link
                class-name="v-order-cancel-details-link"
                :label="
                  order.Items.length === 1
                    ? translate('orderCancel.itemInOrder')
                    : translate('orderCancel.itemsInOrder', { count: order.Items.length })
                "
                :href="`${appConfig.VueSettingsPreRun.Links.CartFinalStep}/${order.ID}`"
              />
            </div>
            <div class="v-order-cancel-details-button">
              <arora-button
                class-name="v-btn-default v-btn-primary v-btn-lg"
                :label="translate('orderCancel.button')"
                @click="() => cancelOrder(order.ID)"
              />
            </div>
          </div>
        </transition>
      </div>
      <hr
        v-if="orders.length > 1 && index < orders.length - 1"
        class="v-order-cancel-divider"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { OrderInfo } from '~types/clientStore'
import type { EmitterEvent } from '~types/common'

import { type GUID, useWindowSize } from '@arora/common'

const { translate, dateTime } = useI18nSanitized()
const clientStore = useClientStore()
const popupStore = usePopupStore()
const { isExtraSmall } = useWindowSize()
const orderCancelEvent: EmitterEvent = 'v-order-cancel'
const { eventOn } = useEmitter()
const appConfig = useAppConfig()

onMounted(async () => {
  await clientStore.initOrdersForCancel()

  eventOn(orderCancelEvent, () => {
    clientStore.loadOrdersForCancel()
  })
})

function makeCreatedAt(created: number): string {
  return dateTime(created * 1000, 'time')
}

const expanded = ref<boolean[]>([])

async function cancelOrder(orderId: GUID): Promise<void> {
  try {
    await clientStore.cancelOrder(orderId)
  } catch (error) {
    if (error?.message) await popupStore.showError(error.message)
  } finally {
    await clientStore.loadOrdersForCancel()
  }
}

const orders = computed<OrderInfo[]>(() => clientStore.OrdersForCancel.data ?? [])

const alwaysShowDate = computed<boolean>(() => appConfig.VueSettingsPreRun.OrderCancelAlwaysShowDate)

function expandDetails(index: number): void {
  expanded.value[index] = !expanded.value[index]
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-order-cancel-wrapper {
  background: variables.$BodyElementsBackground;
  color: variables.$BodyTextColor;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include mixins.sm {
    margin: 15px;
    background: inherit;
  }
}

.v-order-cancel {
  padding: 25px 15px;
  max-width: 1200px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &-title {
    font-size: 30px;
    font-weight: 600;

    flex: 0 0 45%;
    max-width: 45%;

    @include mixins.lg {
      flex: 0 0 55%;
      max-width: 55%;
    }

    @include mixins.sm {
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;

      flex: 0 0 95%;
      max-width: 95%;

      order: 1;

      margin-bottom: 5px;
    }
  }

  @include mixins.sm {
    margin-bottom: 10px;
    border-radius: variables.$BorderRadius;
    background: variables.$BodyElementsBackground;
    box-shadow: variables.$CardShadow;
  }
}

.v-order-cancel-open-details {
  flex: 0 0 25%;
  max-width: 25%;
  color: variables.$PrimaryBackgroundColor;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &-text {
    @include mixins.sm {
      display: none;
    }
  }

  &-sign {
    margin-left: 5px;

    transition: all 0.3s ease;

    transform: rotate(90deg);
  }

  &-sign-rotated {
    transform: rotate(-90deg);
  }

  @include mixins.lg {
    flex: 0 0 10%;
    max-width: 10%;
  }
  @include mixins.sm {
    flex: 0 0 5%;
    max-width: 5%;

    order: 1;

    margin-bottom: 5px;
  }
}

.v-order-cancel-countdown {
  flex: 0 0 30%;
  max-width: 30%;
  height: 65px;
  z-index: 6;

  background: variables.$BodyElementsBackgroundOpaq75;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: none;

  border-radius: variables.$BorderRadius;
  padding: 10px 15px;
  box-shadow: variables.$InputShadow;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &-warning {
    flex: 0 0 60%;
    max-width: 60%;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    @include mixins.sm {
      font-size: 13px;
    }
  }

  .v-countdown-block {
    flex: 0 0 40%;
    max-width: 40%;
  }

  @include mixins.lg {
    flex: 0 0 35%;
    max-width: 35%;
  }

  @include mixins.sm {
    flex: 0 0 100%;
    max-width: 100%;
    background: variables.$FormBackground;
    backdrop-filter: none;

    order: 2;

    margin-bottom: 1.1rem;
  }
}

.v-order-cancel-date {
  flex: 0 0 100%;
  max-width: 100%;

  display: flex;
  justify-content: flex-start;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: variables.$BodyTextColorLight;

  @include mixins.sm {
    order: 1;
    margin-bottom: 1.1rem;
    font-size: variables.$TextSizeMain;
    gap: 10px;
  }
}

.v-order-cancel-details {
  flex: 0 0 100%;
  max-width: 100%;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .v-order-cancel-details-button {
    flex: 0 0 100%;
    max-width: 100%;

    @include mixins.sm {
      margin-bottom: 10px;
      & button {
        width: 100%;
      }
    }
  }

  .v-order-cancel-details-text-wrapper {
    flex: 0 0 calc(70% - 10px);
    max-width: calc(70% - 10px);

    margin: 10px 10px 10px 0;

    font-weight: 500;
    font-size: 18px;
    line-height: 130%;

    @include mixins.sm {
      flex: 0 0 100%;
      max-width: 100%;

      font-size: 12px;
      line-height: 14px;
      padding: 5px;
    }
  }

  &-text {
    @include mixins.sm {
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  &-subtext {
    @include mixins.sm {
      color: variables.$BodyTextColorLight;
    }
  }

  .v-order-cancel-details-pic {
    flex: 0 0 30%;
    max-width: 30%;

    margin: -65px 0 10px;
    overflow: hidden;
    z-index: 5;
    border-radius: variables.$BorderRadius;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .v-order-cancel-details-link {
    margin: 15px 10px 0;
    text-align: center;
    font-size: variables.$TextSizeMain;
    text-decoration: underline;
    @include mixins.sm {
      margin: 0 10px;
    }
  }

  @include mixins.sm {
    order: 2;
  }
}

.v-order-cancel-divider {
  color: variables.$BodyTextColorLight;
  width: 100%;
  max-width: 1175px;

  @include mixins.sm {
    display: none;
  }
}
</style>
